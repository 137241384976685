import React from 'react'

import LayoutContent from '../components/layout/layoutContent'

const NotFoundPage = props => {
    return (
        <LayoutContent {...props} hasVerticalGutters>
            <h1>Not found</h1>
            <p>The page you’re looking for doesn’t exist.</p>
        </LayoutContent>
    )
}

export default NotFoundPage
